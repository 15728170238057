
import RcDomainMap from "../../common/RcDomainMap.js";
import Inspection from './Inspection.js';
import InspectionUtils from './InspectionUtils.js';
import VehicleMap from '../vehicle/VehicleMap.js';

export default class InspectionMap extends RcDomainMap {
  static MODEL_NAME = Inspection.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, InspectionMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new InspectionMap(this.domain, data);
  }
  
  find() {
    var keys = this.keys();
    var found = new InspectionMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.inspections().findById(key);
      var item = new Inspection(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getInspection(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(InspectionUtils.SortByCreatedDate);
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Inspection(this.domain);
  }
  
  getInspection(key) {
    var value = super.get(key);
    return new Inspection(this.domain, value);
  }
  
  findForDay(rcDate, employee) {
    var day = rcDate.day().value();
    var month = rcDate.month().value();
    var year = rcDate.year().value();
    
    var map = new InspectionMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getInspection(key);
      var date = item.createdDate();
      if (date.day().value() == day && date.year().value() == year && date.month().value() == month) {
        if (item.driver().id() === employee.id()) {
          map.add(item);
        }
      }
    }
    
    return map;
  }
  
  findForEmployee(employee) {
    var map = new InspectionMap(this.domain);
    var keys = this.keys();
    
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getInspection(key);
      if (item.driver().id() === employee.id()) {
        map.add(item);
      }
    }
    
    return map;
  }
  
  vehicles() {
    var map = new VehicleMap(this.domain);
    var keys = this.keys();
    
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getInspection(key);
      var vehicles = item.vehicles();
      map.addAll(vehicles);
    }
    
    return map;
  }
}