
import RcDomainMap from "@/domain/common/RcDomainMap.js";

import RepairResult from './RepairResult.js';

export default class RepairResultMap extends RcDomainMap {
  
  static MODEL_NAME = "VehicleInspectionRepairResultMap";
  
  constructor(domain, data) {
    super(domain, data, RepairResultMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new RepairResultMap(this.domain, data);
  }
  
  find() {
    var keys = this.keys();
    var found = new RepairResultMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.repairResults().findById(key);
      var item = new RepairResult(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      values.push(item);
    }
    return values;
  }
  
  getResult(key) {
    var value = super.get(key);
    return new RepairResult(this.domain, value);
  }
  
}