
import RcDomainMap from "../../common/RcDomainMap.js";
import Timesheet from './Timesheet.js';
import TimesheetUtils from './Utils.js';

export default class TimesheetMap extends RcDomainMap {
  static MODEL_NAME = "TimesheetMap";
  
  constructor(domain, data) {
    super(domain, data, TimesheetMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new TimesheetMap(this.domain, data);
  }
  
  getTimesheet(key) {
    var value = super.get(key);
    return new Timesheet(this.domain, value);
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getTimesheet(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(TimesheetUtils.SortByDate);
  }
  
  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Timesheet(this.domain);
  }
  
  findForDay(rcDate, employee) {
    var day = rcDate.day().value() - 1;
    var month = rcDate.month().value();
    var year = rcDate.year().value();
    
    var map = new TimesheetMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getTimesheet(key);
      var itemDay = item.day();
      var itemMonth = item.month();
      if (itemDay == day && item.year() == year && itemMonth == month) {
        if (item.employee().id() === employee.id()) {
          map.add(item);
        }
      }
    }
    
    return map;
  }
  
  pointers() {
    var map = new TimesheetMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = new Timesheet(this.domain, {'@rid': key});
      map.add(item);
    }
    return map;
  }
  
  hasIncomplete() {
    return true;
  }
  
  findFor(employee) {
    var map = new TimesheetMap(this.domain);
    var keys = this.keys();
    
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getTimesheet(key);
      if (item.employee().id() === employee.id()) {
        map.add(item);
      }
    }
    
    return map;
  }
  
  incomplete() {
    var map = new TimesheetMap(this.domain);
    var keys = this.keys();
    
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getTimesheet(key);
      if (!(item.isStatusComplete() || item.isStatusCompleteOffDay())) {
        map.add(item);
      }
    }
    
    return map;
  }
}