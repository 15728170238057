
//import ConstUtils from '@/utils/ConstUtils.js';

export default class AddressEvents {
  
  constructor(domain) {
    this.domain = domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  static Save = {
    Names: {
      Request: 'AddressSaveRequestEvent',
      Response: 'AddressSaveResponseEvent',
    },

    Errors: {
      //
    },

    Request: function(data) {
      var request = {
        Address: data,
        handler: 'AddressSaveRequestEvent',
        eventName: 'AddressSaveRequestEvent',
      };
      return request;
    },
  }

  
  static List = {
    Names: {
      Response: 'AddressListResponseEvent',
      Request: 'AddressListRequestEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(afterName, page) {
      const event = {
          eventName: AddressEvents.List.Names.Request,
          handler: AddressEvents.List.Names.Request,
          page: page,
          afterName,
      };
      return event;
    },
  }
  
}