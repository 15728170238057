
//import ConstUtils from '@/utils/ConstUtils.js';
import CheckList from './CheckList.js';

export default class CheckListEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var request = CheckListEvents.List.Request(filter);
    return this.eventsDomain.initRequest(request);
  }
  
  save(checklist) {
    var request = CheckListEvents.Save.Request(checklist);
    return this.eventsDomain.initRequest(request);
  }
  
  static List = {
    Names: {
      Request:  'CheckListListRequestEvent',
      Response: 'CheckListListResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(filter) {
      const event = {
          eventName: CheckListEvents.List.Names.Request,
          handler: CheckListEvents.List.Names.Request,
      };
      event['filter'] = filter.data;
      return event;
    },
  }
  
  static Save = {
      Names: {
        Request:  'CheckListSaveRequestEvent',
        Response: 'CheckListSaveResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(checklist) {
        const event = {
            eventName: CheckListEvents.Save.Names.Request,
            handler: CheckListEvents.Save.Names.Request,
        };
        event[CheckList.MODEL_NAME] = checklist.data;
        return event;
      },
    }
}