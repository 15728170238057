
//import ConstUtils from '@/utils/ConstUtils.js';

export default class CheckInEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var request = CheckInEvents.List.Request(filter);
    return this.eventsDomain.initRequest(request);
  }
  
  static List = {
    Names: {
      Request:  'CheckInListRequestEvent',
      Response: 'CheckInListResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(company) {
      const event = {
          eventName: CheckInEvents.List.Names.Request,
          handler: CheckInEvents.List.Names.Request,
      };
      if (company && company.isNew()) {
        event['filter'] = company.data;
      }
      return event;
    },
  }
  
}