
import MapUtils from '@/utils/MapUtils.js';

export default class AdminEvents {
  
  constructor(domain) {
    this.domain = domain;
  }
  
  static SignUp = {
      Names: {
        Response: 'AdminUserSignUpResponseEvent',
        Request: 'AdminUserSignUpRequestEvent',
      },

      Errors: {
        find: function(error) {
          if (this.EmailExists.messageId === error.messageId) {
            return AdminEvents.UserSignUp.Errors.EmailExists;
          }
          if (this.CompanyExists.messageId === error.messageId) {
            return AdminEvents.UserSignUp.Errors.CompanyExists;
          }
          return error;
        },
        EmailExists: {
          id: 'EmailExists',
          msg: 'A user with that email already exists',
          details: '',
          isEmailExists: function() {
            return true;
          },
          isCompanyExists: function() {
            return false;
          },
        },
        CompanyExists: {
          id: 'CompanyExists',
          msg: 'A company with that website already exists',
          details: '',
          isEmailExists: function() {
            return false;
          },
          isCompanyExists: function() {
            return true;
          },
        },
      },

      Request: function(kvps) {
        var event = {
          handler: 'AdminUserSignUpRequestEvent',
          eventName: 'AdminUserSignUpRequestEvent',
        }
        event = MapUtils.updateMap(kvps, event);
        return event;
      },
      
    }
}