
import RcDomainObject from "../../common/RcDomainObject";
import Employee from "../employee/Employee.js";
import RcDate from '@/domain/session/time/RcDate.js';
import ImageMap from "@/domain/model/image/ImageMap.js";

export default class Comment extends RcDomainObject {
  
  static MODEL_NAME = 'VehicleInspectionComment';
  
  static FIELDS = {
    TEXT : 'text',
    EMPLOYEE : 'Employee',
    IMAGES: ImageMap.MODEL_NAME,
  }
  
  constructor(domain, data) {
    super(domain, data, Comment.MODEL_NAME);
  }

  hasImages() {
    return this.images().size() > 0;
  }
  
  images() {
    var obj = this.get(Comment.FIELDS.IMAGES);
    return new ImageMap(this.domain, obj);
  }
  
  fields() {
    return Comment.FIELDS;
  }
  
  find() {
    var result = this.domain.comments().findById(this.id());
    if (result.id() !== this.id()) {
      result = this;
    }
    
    return result;
  }
  
  text() {
    return this.getString(Comment.FIELDS.TEXT);
  }
  
  withText(text) {
    this.put(Comment.FIELDS.TEXT, text);
    return this;
  }
  
  createdDate() {
    var createdDate = this.get(RcDomainObject.DefaultFields.CREATED_DATE);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate;
  }
  
  createdDateDisplay() {
    var createdDate = this.get(RcDomainObject.DefaultFields.CREATED_DATE);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  employee() {
    var obj = this.get(Comment.FIELDS.EMPLOYEE);
    var employee = new Employee(this.domain, obj);
    return employee;
  }
  
  copy() {
    var data = this._copy();
    return new Comment(this.domain, data);
  }
}