
import RcDomainMap from "@/domain/common/RcDomainMap.js";

import Answer from './Answer.js';
import OptionMap from '@/domain/model/schedule/question/option/OptionMap.js';

export default class AnswerMap extends RcDomainMap {
  
  static MODEL_NAME = "VehicleInspectionAnswerMap";
  
  constructor(domain, data) {
    super(domain, data, AnswerMap.MODEL_NAME);
  }

  find() {
    var keys = this.keys();
    var found = new AnswerMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.answers().findById(key);
      var item = new Answer(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getAnswer(key);
      values.push(item);
    }
    return values;
  }
  
  getAnswer(key) {
    var value = super.get(key);
    return new Answer(this.domain, value);
  }
  
  copy() {
    var data = this._copy();
    return new AnswerMap(this.domain, data);
  }
  
  answerFor(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        return answer;
      }
    }
    return new Answer(this.domain, {});
  }
  
  majors(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var options = answer.options().find();
        return options.majors();
      }
    }
    return new OptionMap(this.domain, {});
  }
  
  majorsFor(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var options = answer.options().find();
        if (options.hasMajor()) {
          return true;
        }
      }
    }
    return false;
  }
  
  minors(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var options = answer.options().find();
        return options.minors();
      }
    }
    return new OptionMap(this.domain, {});
  }
  
  minorsFor(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var options = answer.options().find();
        if (options.hasMinor()) {
          return true;
        }
      }
    }
    return false;
  }
  
  naFor(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var options = answer.options().find();
        if (options.hasNa()) {
          return true;
        }
      }
    }
    return false;
  }
  
  okFor(question) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var options = answer.options().find();
        if (options.hasOk()) {
          return true;
        }
      }
    }
    return false;
  }
  
  isRepaired(question, option) {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.question().id() === question.id()) {
        var repairs = answer.repairs().find();
        return repairs.isRepaired(option);
      }
    }
    return false;
  }
  
  hasUnfixedMajors() {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.hasUnfixedMajors()) {
        return true;
      }
    }
    return false;
  }
  
  hasUnfixedMinors() {
    var list = this.list();
    for (var index = 0; index < list.length; index++) {
      var answer = list[index];
      if (answer.hasUnfixedMinors()) {
        return true;
      }
    }
    return false;
  }
}