
//import ConstUtils from '@/utils/ConstUtils.js';

import Timesheet from '@/domain/model/timesheet/Timesheet.js';

export default class TimesheetEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter, includeUsers) {
    var event = TimesheetEvents.List.Request(filter, includeUsers);
    return this.eventsDomain.initRequest(event);
  }
  
  details(id) {
    var event = TimesheetEvents.Details.Request(id);
    return this.eventsDomain.initRequest(event);
  }
  
  reminder(day) {
    var event = TimesheetEvents.Reminder.Request(day);
    return this.eventsDomain.initRequest(event);
  }
  
  rebuildPdf(id) {
    var event = TimesheetEvents.RebuildPdf.Request(id);
    return this.eventsDomain.initRequest(event);
  }
  
  static List = {
      Names: {
        Request: 'TimesheetListRequestEvent',
        Response: 'TimesheetListResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(filter, includeUsers) {
        var event = {
          filter: filter.data,
          handler: TimesheetEvents.List.Names.Request,
          eventName: TimesheetEvents.List.Names.Request,
        }
        var params = {};
        params["includeUsers"] = includeUsers;
        event["Params"] = params;
        return event;
      },
    }
  
  static Save = {
      Names: {
        Request: 'TimesheetSaveRequestEvent',
        Response: 'TimesheetSaveResponseEvent',
      },

      Errors: {
        //
      },

      Request: function(sheet) {
        var request = {
          Timesheet: sheet,
          handler: TimesheetEvents.Save.Names.Request,
          eventName: TimesheetEvents.Save.Names.Request,
        };
        return request;
      },
    }
  
  static Details = {
      Names: {
        Request: 'TimesheetDetailsRequestEvent',
        Response: 'TimesheetDetailsResponseEvent',
      },

      Errors: {
        //
      },

      Request: function(sheet) {
        var request = {
          Timesheet: sheet,
          handler: TimesheetEvents.Details.Names.Request,
          eventName: TimesheetEvents.Details.Names.Request,
        };
        return request;
      },
    }
  
  static Reminder = {
      Names: {
        Request: 'TimesheetReminderRequestEvent',
        Response: 'TimesheetReminderResponseEvent',
      },

      Errors: {
        //
      },

      Request: function(day) {
        var request = {
          startDay: day,
          handler: TimesheetEvents.Reminder.Names.Request,
          eventName: TimesheetEvents.Reminder.Names.Request,
        };
        return request;
      },
    }
  
  static RebuildPdf = {
      Names: {
        Request: 'TimesheetRebuildPdfRequestEvent',
        Response: 'TimesheetRebuilPdfResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(data) {
        var event = {
          handler: TimesheetEvents.RebuildPdf.Names.Request,
          eventName: TimesheetEvents.RebuildPdf.Names.Request,
        }
        event[Timesheet.MODEL_NAME] = data;
        return event;
      },
    }
  
}