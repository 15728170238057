
import RcDomainMap from "@/domain/common/RcDomainMap.js";

import OptionMap from "@/domain/model/schedule/question/option/OptionMap.js";
import Repair from "./Repair.js";

export default class RepairMap extends RcDomainMap {
  
  static MODEL_NAME = "VehicleInspectionRepairMap";
  
  constructor(domain, data) {
    super(domain, data, RepairMap.MODEL_NAME);
  }

  find() {
    var keys = this.keys();
    var found = new RepairMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.repairs().findById(key);
      var item = new Repair(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getRepair(key);
      values.push(item);
    }
    return values;
  }
  
  getRepair(key) {
    var value = super.get(key);
    return new Repair(this.domain, value);
  }
  
  isRepaired(option) {
    var items = this.list();
    for (var index = 0; index < items.length; index++) {
      var repair = items[index];
      if (repair.option().id() === option.id()) {
        return true;
      }
    }
    return false;
  }
  
  contains(options) {
    var keys = options.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var option = options.getOption(key);
      if (!this.containsOption(option)) {
        return false;
      }
    }
    return true;
  }
  
  containsOption(option) {
    var items = this.list();
    for (var index = 0; index < items.length; index++) {
      var repair = items[index].find();
      if (repair.option().id() === option.id()) {
        return true;
      }
    }
    return false;
  }
  
  options() {
    var items = this.list();
    var map = new OptionMap(this.domain);
    for (var index = 0; index < items.length; index++) {
      var repair = items[index];
      var option = repair.option();
      map.add(option);
    }
    return map;
  }
  
  copy() {
    var data = this._copy();
    return new RepairMap(this.domain, data);
  }
  
}