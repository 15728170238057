
export default class VehicleUtils {
  
  static SortByCreatedDate = function(a, b) {
    if (!a || !b) {
      return 0;
    }
    if (a.createdDate() < b.createdDate()) {
      return -1;
    }
    if (a.createdDate() > b.createdDate()) {
      return 1;
    }
    return 0;
  }
  
  static SortByName = function(a,b) {
    if (a.name() > b.name()) {
      return 1;
    }
    if (a.name() < b.name()) {
      return -1;
    }
    return 0;
  }

  static SortByNameDesc = function(a,b) {
    if (a.name() < b.name()) {
      return 1;
    }
    if (a.name() > b.name()) {
      return -1;
    }
    return 0;
  }

}