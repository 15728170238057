
export default class RcRequestEvent {
  
  constructor(domain, data) {
    this.data = data;
    this.domain = domain;
    this.logger = this.domain.logger();
  }
  
  name() {
    return this.data.eventName;
  }
  
  is(name) {
    return this.data.eventName == name;
  }

  with(key, value) {
    if (value && value.data) {
      this.data[key] = value.data;
    } else {
      this.data[key] = value;
    }
    return this;
  }
  
  send(listener, context) {
   this.logger.info("Event data: {0}", [JSON.stringify(this.data, null, 2)]);
    if (listener) {
      this.logger.info("Sending event named: {0} ({1}) {2} ", [this.data.eventName, this.data['@rid'], listener.name]);
      this.domain.events().addListener(this.data['@rid'], listener, context);
    } else {
      this.logger.info("Sending event named: {0} ({1}) {2} ", [this.data.eventName, this.data['@rid'], "No Listener"]);
    }
    this.domain.client().socket.connection.sendObj(this.data);
  }
}

