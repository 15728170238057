
import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';

export default class CompanySaveResponse extends RcResponseEvent {

  static NAME = 'CompanySaveResponseEvent';
  
  constructor(domain, data) {
    super(domain, data);
  }
  
}