
//import ConstUtils from '@/utils/ConstUtils.js';

import Inspection from '@/domain/model/inspection/Inspection.js';

export default class InspectionEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter, includeUsers, includeVehicles) {
    var event = InspectionEvents.List.Request(filter, includeUsers, includeVehicles);
    return this.eventsDomain.initRequest(event);
  }
  
  save(inspection) {
    var data = "";
    if (inspection.data) {
      data = inspection.data;
    } else {
      data = inspection;
    }
    var event = InspectionEvents.Save.Request(data);
    return this.eventsDomain.initRequest(event);
  }

  rebuild(id) {
    var event = InspectionEvents.Rebuild.Request(id);
    return this.eventsDomain.initRequest(event);
  }
  
  deleteInspection(id) {
    var event = InspectionEvents.Delete.Request(id);
    return this.eventsDomain.initRequest(event);
  }
  
  email(id, email, isCompanyManager) {
    var event = InspectionEvents.Email.Request(id, email);
    event["companyManager"] = isCompanyManager;
    return this.eventsDomain.initRequest(event);
  }
  
  static Save = {
      Names: {
        Request: 'VehicleInspectionSaveRequestEvent',
        Response: 'SaveVehicleInspectionResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(data) {
        var event = {
          handler: InspectionEvents.Save.Names.Request,
          eventName: InspectionEvents.Save.Names.Request,
        }
        event[Inspection.MODEL_NAME] = data;
        return event;
      },
    }
  
  static List = {
      Names: {
        Request: 'VehicleInspectionListRequestEvent',
        Response: 'VehicleInspectionListResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(filter, includeUsers, includeVehicles) {
        var event = {
          filter: filter.data,
          handler: "VehicleInspectionListRequestEvent",
          eventName: "VehicleInspectionListRequestEvent",
        }
        var params = {};
        params["includeUsers"] = includeUsers;
        params["includeVehicles"] = includeVehicles;
        event["Params"] = params;
        return event;
      },
    }
  
  
  static Details = {
    Names: {
      Request: 'VehicleInspectionDetailsRequestEvent',
      Response: 'VehicleInspectionDetailsResponseEvent',
    },

    Errors: {
      //
    },

    Request: function(inspectionId) {
      var request = {
        VehicleInspection: inspectionId,
        handler: 'VehicleInspectionDetailsRequestEvent',
        eventName: 'VehicleInspectionDetailsRequestEvent',
      }
      return request
    },
  }
  
  static Rebuild = {
      Names: {
        Request: 'VehicleInspectionRebuildRequestEvent',
        Response: 'VehicleInspectionRebuildResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(data) {
        var event = {
          handler: InspectionEvents.Rebuild.Names.Request,
          eventName: InspectionEvents.Rebuild.Names.Request,
        }
        event[Inspection.MODEL_NAME] = data;
        return event;
      },
    }
  
  static Delete = {
      Names: {
        Request: 'VehicleInspectionDeleteRequestEvent',
        Response: 'VehicleInspectionDeleteResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(data) {
        var event = {
          handler: InspectionEvents.Delete.Names.Request,
          eventName: InspectionEvents.Delete.Names.Request,
        }
        event[Inspection.MODEL_NAME] = data;
        return event;
      },
    }
  
  static Email = {
      Names: {
        Request: 'VehicleInspectionMtoEmailRequestEvent',
        Response: 'VehicleInspectionMtoEmailResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(data, email) {
        var event = {
          handler: InspectionEvents.Email.Names.Request,
          eventName: InspectionEvents.Email.Names.Request,
        }
        event[Inspection.MODEL_NAME] = data;
        if (email) {
          event['email'] = email;
        }
        return event;
      },
    }
}