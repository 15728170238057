
//import ConstUtils from '@/utils/ConstUtils.js';

export default class ClientEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  exception(clientException) {
    var event = ClientEvents.Exception.Request(clientException);
    return this.eventsDomain.initRequest(event);
  }
  
  static Exception = {
    Names: {
      Request: 'ClientExceptionSaveRequestEvent',
      Response: 'ClientExceptionResSaveponseEvent',
    },

    Errors: {
      //
    },

    Request: function(clientException) {
      var request = {
        ClientException: clientException.data,
        handler: ClientEvents.Exception.Names.Request,
        eventName: ClientEvents.Exception.Names.Request,
      };
      return request;
    },
  }

  
  
}