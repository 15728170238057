
//import ConstUtils from '@/utils/ConstUtils.js';
import MapUtils from '@/utils/MapUtils.js';

export default class CardEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  save(details, address) {
    var event = new CardEvents.Save.Request(details, address);
    var wrappedEvent = this.eventsDomain.initRequest(event);
    return wrappedEvent;
  }
  
  static Save = {
      Names: {
        Request:  'CardSaveRequestEvent',
        Response: 'CardSavedResponseEvent',
      },

      Errors: {
        IncorrectOldPassword: {
          id: 'IncorrectOldPassword',
          msg: 'The old password is incorrect',
          details: '',
        },
      },

      Request: function(cardDetails, cardAddress) {
        var event = {
            ccAddress: cardAddress,
            handler: CardEvents.Save.Names.Request,
            eventName: CardEvents.Save.Names.Request,
        }
        MapUtils.updateMap(cardDetails, event);
        return event
      },
    }
  
}