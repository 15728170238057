
import CompanySaveResponse from './CompanySaveResponse.js';

export default class CompanyEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    var found = this.findByName(name);
    return found != null;
  }
  
  findByName(name, data) {
    if (name.startsWith(CompanySaveResponse.NAME)) {
      return new CompanySaveResponse(this.domain, data);
    }
    return null;
  }
  
  list(filter) {
    var event = CompanyEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  details(companyId) {
    var event = CompanyEvents.Details.Request(companyId);
    return this.eventsDomain.initRequest(event);
  }
  
  save() {
    return {
      request: function(data) {
        return CompanyEvents.Save.Request(data);
      },
      response: function(data) {
        return CompanyEvents.Save.Response(data);
      }
    }
  }
  
  statsDetails(interval) {
    var event = CompanyEvents.StatsDetails.Request(interval);
    return this.eventsDomain.initRequest(event);
  }

  statsList(filter) {
    var event = CompanyEvents.StatsList.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  static StatsDetails = {
      Names: {
        Request:  'CompanyStatsRequestEvent',
        Response: 'CompanyStatsResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(interval) {
        const event = {
          eventName: CompanyEvents.StatsDetails.Names.Request,
          handler: CompanyEvents.StatsDetails.Names.Request,
        };
        if (!interval) {
          interval = "today";
        }
        event['interval'] = interval;
        return event;
      },
    }
  
  static StatsList = {
      Names: {
        Request:  'CompanyStatsListRequestEvent',
        Response: 'CompanyStatsListResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(filter) {
        const event = {
          eventName: CompanyEvents.StatsList.Names.Request,
          handler: CompanyEvents.StatsList.Names.Request,
          filter: filter.data,
        };
        return event;
      },
    }
  
  static Save = {
    Names: {
      Request: 'CompanySaveRequestEvent',
      Response: 'CompanySaveResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(data) {
      var request = {
        Company: data,
        handler: CompanyEvents.Save.Names.Request,
        eventName: CompanyEvents.Save.Names.Request,
      };
      return request;
    },
    Response: function(data) {
      var response = {
          data: data,
      }
      return response;
    }
  }

  
  static List = {
    Names: {
      Response: 'CompanyListResponseEvent',
      Request: 'CompanyListRequestEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(filter) {
      const event = {
          filter: filter.data,
          eventName: CompanyEvents.List.Names.Request,
          handler: CompanyEvents.List.Names.Request,
      };
      return event;
    },
  }
  
  static Details = {
      Names: {
        Response: 'CompanyDetailsResponseEvent',
        Request: 'CompanyDetailsRequestEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(companyId) {
        const event = {
            Company: companyId,
            eventName: CompanyEvents.Details.Names.Request,
            handler: CompanyEvents.Details.Names.Request,
        };
        return event;
      },
    }
  
}