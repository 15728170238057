
//import ConstUtils from '@/utils/ConstUtils.js';

export default class AppEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  statsList(filter) {
    var request = AppEvents.StatsList.Request(filter);
    return this.eventsDomain.initRequest(request);
  }
  
  statsDetails(interval) {
    var request = AppEvents.StatsDetails.Request(interval);
    return this.eventsDomain.initRequest(request);
  }
  
  static StatsDetails = {
    Names: {
      Request:  'AppStatsDetailsRequestEvent',
      Response: 'AppStatsDetailsResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(interval) {
      const event = {
        eventName: AppEvents.StatsDetails.Names.Request,
        handler: AppEvents.StatsDetails.Names.Request,
      };
      if (!interval) {
        interval = "today";
      }
      event['interval'] = interval;
      return event;
    },
  }
  
  static StatsList = {
      Names: {
        Request:  'AppStatsListRequestEvent',
        Response: 'AppStatsListResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'NotFound',
          details: ''
        },
      },

      Request: function(filter) {
        const event = {
          eventName: AppEvents.StatsList.Names.Request,
          handler: AppEvents.StatsList.Names.Request,
        };
        event['filter'] = filter.data;
        return event;
      },
    }
}