
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import StringUtils  from '@/utils/StringUtils.js';

export default class TimesheetEntry extends RcDomainObject {
  
  static MODEL_NAME = "TimesheetEntry";
  
  constructor(domain, data) {
    super(domain, data, TimesheetEntry.MODEL_NAME);
  }
  
  static Fields = {
    HOUR_END: "endHour",
    QUARTER_END: "endQuarter",
    TIME_ID_END: "endTimeId",
    LOCATION: "location",
    REMARKS: "remarks",
    HOUR_START: "startHour",
    QUARTER_START: "startQuarter",
    TIME_ID_START: "startTimeId",
    ENTRY_TYPE: "type",
  }
  
  static Values = {
    EntryType: {
      ON_DUTY_NOT_DRIVING: "onDutyNotDriving",
      ON_DUTY_DRIVING: "onDutyDriving",
      OFF_DUTY_AWAY: "offDuty",
      OFF_DUTY_SLEEPER: "offDutySleeper",
      
      isOffDutyAway: function(entryType) {
        return this.OFF_DUTY_AWAY.toUpperCase() === entryType.toUpperCase();
      },
      isOnDutyDriving: function(entryType) {
        return this.ON_DUTY_DRIVING.toUpperCase() === entryType.toUpperCase();
      },
      isOnDutyNotDriving: function(entryType) {
        return this.ON_DUTY_NOT_DRIVING.toUpperCase() === entryType.toUpperCase();
      }
    },
  }
  
  fields() {
    return TimesheetEntry.FIELDS;
  }
  
  static newTimeId(hour, quarter) {
    var id = hour * 4 + quarter;
    return id;
  }
  
  totalQuarters() {
    var total = this.timeIdEnd() - this.timeIdStart();
    total = total + 1;
    return total;
  }
  
  quarterStart() {
    return this.get(TimesheetEntry.Fields.QUARTER_START);
  }
  
  hourStart() {
    return this.get(TimesheetEntry.Fields.HOUR_START);
  }
  
  quarterEnd() {
    return this.get(TimesheetEntry.Fields.QUARTER_END);
  }
  
  hourEnd() {
    return this.get(TimesheetEntry.Fields.HOUR_END);
  }
  
  timeIdStart() {
    return this.get(TimesheetEntry.Fields.TIME_ID_START);
  }
  
  isTimeIdStartSet() {
    var result = this.timeIdStart();
    return result >= 0;
  }
  
  timeIdEnd() {
    return this.get(TimesheetEntry.Fields.TIME_ID_END);
  }
  
  entryType() {
    return this.getString(TimesheetEntry.Fields.ENTRY_TYPE);
  }
  
  isEntryType(entryType) {
    return StringUtils.equals(this.entryType(), entryType);
  }
  
  isOffDuty() {
    return StringUtils.equals(TimesheetEntry.Values.EntryType.OFF_DUTY_AWAY, this.entryType());
  }
  
  isOffDutySleeper() {
    return StringUtils.equals(TimesheetEntry.Values.EntryType.OFF_DUTY_SLEEPER, this.entryType());
  }
  
  isOnDutyDriving() {
    return StringUtils.equals(TimesheetEntry.Values.EntryType.ON_DUTY_DRIVING, this.entryType());
  }
  
  isOnDuty() {
    return StringUtils.equals(TimesheetEntry.Values.EntryType.ON_DUTY_NOT_DRIVING, this.entryType());
  }
  
  remarks() {
    return this.getString(TimesheetEntry.Fields.REMARKS);
  }
  
  location() {
    return this.getString(TimesheetEntry.Fields.LOCATION);
  }
}