
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import DateUtils from '@/utils/DateUtils.js';
import RepairMap from '@/domain/model/answer/repair/RepairMap.js';
import Employee from '@/domain/model/employee/Employee.js';
import Signature from '@/domain/model/user/Signature.js';
import RcDate from '@/domain/session/time/RcDate.js';

export default class RepairResult extends RcDomainObject {
  
  static MODEL_NAME = "VehicleInspectionRepairResult";
  
  static Fields = {
    WORK_ORDER: "workOrder",
    REPAIR_DATE: "repairDate",
    EMPLOYEE: "Employee",
    SIGNATURE: "UserSignature",
    REPAIRS: "VehicleInspectionRepairMap",
  };
  
  constructor(domain, data) {
    super(domain, data, RepairResult.MODEL_NAME);
  }
  
  fields() {
    return RepairResult.FIELDS;
  }
  
  repairDateDisplay() {
    var createdDate = this.repairDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate.displayAs_YYYY_MM_DD_HH_MM_SS_A();
  }
  
  repairDate() {
    return this.getInt(RepairResult.Fields.REPAIR_DATE);
  }
  
  withRepairs(repairs) {
    var rprs = this.repairs();
    rprs.addAll(repairs);
    this.with(rprs);
    return this;
  }
  
  repairs() {
    var repairMap = new RepairMap(this.domain, this.get(RepairResult.Fields.REPAIRS));
    return repairMap;
  }
  
  repairList() {
    return this.repairs().find().list();
  }
  
  withWorkOrder(workOrder) {
    this.put(RepairResult.Fields.WORK_ORDER, workOrder);
    return this;
  }
  
  workOrders() {
    return this.getString(RepairResult.Fields.WORK_ORDER);
  }
  
  employee() {
    var data = this.get(RepairResult.Fields.EMPLOYEE);
    return new Employee(this.domain, data);
  }
  
  withEmployeeCurrent() {
    this.withEmployee(this.domain.session().employee());
    return this;
  }
  
  withEmployee(employee) {
    this.with(employee);
    return this;
  }
  
  withSignatureCurrentUser() {
    this.withSignature(this.domain.session().user().signature());
    return this;
  }
  
  withSignature(signature) {
    this.with(signature);
    return this;
  }
  
  signature() {
    var data = this.get(RepairResult.Fields.SIGNATURE);
    return new Signature(this.domain, data);
  }
  
  withRepairDateNow() {
    this.withRepairDate(DateUtils.now());
    return this;
  }
  
  withRepairDate(time) {
    this.put(RepairResult.Fields.REPAIR_DATE, time);
    return this;
  }
}