
//import ConstUtils from '@/utils/ConstUtils.js';

export default class ScheduleEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var event = new ScheduleEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  static List = {
    Names: {
      Request: 'VehicleScheduleListRequestEvent',
      Response: 'VehicleScheduleListResponseEvent',
    },

    Errors: {
      //
    },

    Request: function(filter) {
      var request = {
        handler: ScheduleEvents.List.Names.Request,
        eventName: ScheduleEvents.List.Names.Request,
      };
      request['Filter'] = filter
      return request;
    },
  }

  
}