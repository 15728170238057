
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Question from "@/domain/model/schedule/question/Question.js";

import OptionMap from "@/domain/model/schedule/question/option/OptionMap.js";
import RepairMap from "@/domain/model/answer/repair/RepairMap.js";

import Catalog from "@/domain/session/Catalog.js";

//import StringUtils from '@/utils/StringUtils.js';

export default class Answer extends RcDomainObject {
  
  static MODEL_NAME = "VehicleInspectionAnswer";
  
  static Fields = {
    NAME: 'name',
    QUESTION: 'VehicleScheduleQuestion',
    OPTIONS: 'VehicleScheduleQuestionOptionMap',
    REPAIRS: 'VehicleInspectionRepairMap',
    MINOR_REMARKS: 'FreeFormMinor',
    MAJOR_REMARKS: 'FreeFormMajor'
  }
  
  constructor(domain, data) {
    super(domain, data, Answer.MODEL_NAME);
    this.C = new Catalog();
  }

  find() {
    return this.domain.answers().findById(this.id());
  }
  
  name() {
    return this.getString(Answer.Fields.NAME);
  }
  
  question() {
    var obj = this.get(Answer.Fields.QUESTION);
    return new Question(this.domain, obj);
  }
 
  options() {
    var obj = this.get(Answer.Fields.OPTIONS);
    return new OptionMap(this.domain, obj);
  }

  repairs() {
    var obj = this.get(Answer.Fields.REPAIRS);
    return new RepairMap(this.domain, obj);
  }
  
  addRepair(repair) {
    var repairs = this.repairs();
    repairs.add(repair);
    this.withRepairs(repairs);
  }
  
  withRepairs(repairs) {
    this.put(Answer.Fields.REPAIRS, repairs.data);
    return this;
  }
  
  remarksMajor() {
    var obj = this.getString(Answer.Fields.MAJOR_REMARKS);
    return obj;
  }
  
  remarksMinor() {
    var obj = this.getString(Answer.Fields.MINOR_REMARKS);
    return obj;
  }
  
  hasUnfixedMajors() {
    var repairs = this.repairs();
    var options = this.options().find().majors();
    if (options.isEmpty()) {
      return false;
    }
    return !repairs.contains(options);
  }
  
  hasUnfixedMinors() {
    var repairs = this.repairs();
    var options = this.options().find().minors();
    if (options.isEmpty()) {
      return false;
    }
    return !repairs.contains(options);
  }
  
  copy() {
    var data = this._copy();
    return new Answer(this.domain, data);
  }
}