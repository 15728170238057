
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Option from "@/domain/model/schedule/question/option/Option.js";
import Catalog from "@/domain/session/Catalog.js";


export default class Repair extends RcDomainObject {
  
  static MODEL_NAME = "VehicleInspectionRepair";
  
  static Fields = {
    REMARKS: 'remarks',
    OPTION: 'VehicleScheduleQuestionOption',
  }
  
  constructor(domain, data) {
    super(domain, data, Repair.MODEL_NAME);
    this.C = new Catalog();
  }

  find() {
    if (this.isNew()) {
      return this;
    }
    return this.domain.repairs().findById(this.id());
  }
  
  option() {
    var obj = this.get(Repair.Fields.OPTION);
    return new Option(this.domain, obj);
  }
  
  withOption(option) {
    this.put(Repair.Fields.OPTION, option.data);
    return this;
  }
  
  remarks() {
    return this.getString(Repair.Fields.REMARKS);
  }
  
  withRemarks(remarks) {
    this.put(Repair.Fields.REMARKS, remarks);
    return this;
  }
}